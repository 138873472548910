<template>
    <div class="view_box">
        <div ></div>
        <Report v-loading="loading" ref="report" :info="task" :spendTime="spendTime" :record="record"
         @download="download" @share="share" @email="dialog=true" @history="history">
            <div class="page-box" v-for="(item,index) in data" :key="index">
                        <div class="item-box"
                        v-for="(item1,index1) in item.list" :key="index1">
                            <el-card class="box-card" v-if="item1.menu=='selector'">
                            <Selector :item="item1"/>
                            </el-card>
                            <ListAdd :item="item1" v-else-if="item1.menu=='ListAdd'"/>
                            <Nav :item="item1" v-else-if="item1.menu=='nav'" :tid="tid"/>
                            <el-card class="box-card" v-else-if="item1.menu=='checkBox'" >
                            <CheckBox :item="item1"/>
                            </el-card>
                            <el-card class="box-card" v-else-if="item1.menu=='radio||input'">
                            <Radio :item="item1" />
                            </el-card>
                            <el-card class="box-card" v-else-if="item1.menu=='upload'">
                            <Upload :item="item1" :title="true"/>
                            </el-card>
                            <el-card class="box-card" v-else-if="item1.menu=='text'&&item1.data">
                            <TextView :item="item1" />
                            </el-card>
                            <el-card class="box-card" v-else-if="item1.menu=='signature'">
                            <signature :item="item1" />
                            </el-card>
                        </div>
            </div>
        </Report>
        <!-- dialog -->
        <el-dialog title="请输入收件箱" :visible.sync="dialog">
            <el-form :model="form" ref="ruleForm" v-loading="loading">
                <el-form-item label="邮箱地址" prop="email" 
                :rules="[
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
    ]">
                <el-input v-model="form.email" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div>
                <el-radio v-model="downForm.type" label="pdf">pdf</el-radio>
                <el-radio v-model="downForm.type" label="word">word</el-radio>
            </div>
            <div v-if="downForm.type=='pdf'" class="span-title">
                <el-switch
                v-model="downForm.compressed"
                active-text="压缩文件"
                inactive-text="不压缩文件">
                </el-switch>
            </div>
            <div v-if="downForm.type=='pdf'" class="span-title">
                <el-switch
                v-model="downForm.contents"
                active-text="显示目录"
                inactive-text="不显示目录">
                </el-switch>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog = false">取 消</el-button>
                <el-button type="primary" @click="email" :disabled="loading">确 定</el-button>
            </div>
        </el-dialog>
        <!-- history历史记录 -->
        <el-drawer
        title="操作日志"
        :model="false"
        :visible.sync="drawer"
        direction="rtl"
        :size="size">
            <el-table :data="record">
            <el-table-column property="time" label="时间" ></el-table-column>
            <el-table-column property="desc" label="类型" ></el-table-column>
            <el-table-column property="address" label="地址"></el-table-column>
            <el-table-column property="data" label="数据">
                <template slot-scope="scope">
                    <div v-if="scope.row.type=='sign'">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.data"
                        fit="cover"></el-image>
                    </div>
                    <div v-else-if="scope.row.type=='upload'">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.data"
                        fit="cover"></el-image>
                    </div>
                    <div v-else>
                        {{scope.row.data}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="editor" label="操作人"></el-table-column>
            </el-table>
        </el-drawer>
        <!-- 显示二维码 -->
        <el-dialog title="微信扫码查看报告"
        center :visible.sync="dialogTableVisible">
            <div class="imgCode">
            <el-image
                style="width: 180px; height: 180px"
                :src="codeUrl"
                fit="cover"></el-image>
            </div>
        </el-dialog>
        <!-- 下载报告 -->
        <el-dialog
            title="提示"
            :visible.sync="showDownload"
            v-loading="loading"
            :modal="false"
            width="360px">
            <span class="span-title">请选择下载格式</span>
            <div>
                <el-radio v-model="downForm.type" label="pdf">pdf</el-radio>
                <el-radio v-model="downForm.type" label="word">word</el-radio>
            </div>
            <div v-if="downForm.type=='pdf'" class="span-title">
                <el-switch
                v-model="downForm.compressed"
                active-text="压缩文件"
                inactive-text="不压缩文件">
                </el-switch>
            </div>
            <div v-if="downForm.type=='pdf'" class="span-title">
                <el-switch
                v-model="downForm.contents"
                active-text="显示目录"
                inactive-text="不显示目录">
                </el-switch>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showDownload = false">取 消</el-button>
                <el-button type="primary" @click="donwloadThisFile(false)">确 定</el-button>
            </span>
            </el-dialog>
    </div>
</template>
<script>
const ListJson = [];//require('@/utils/list.json') 
import wx from 'weixin-js-sdk'
import Report from '@/components/Report'
import Selector from  '@/components/Report/Selector'
import ListAdd from '@/components/Report/ListAdd'
import Nav from '@/components/Report/Nav'
import CheckBox from '@/components/Report/CheckBox'
import Radio from '@/components/Report/Radio'
import Upload from '@/components/Report/Upload'
import Text from '@/components/Report/Text'
import signature from '@/components/Report/Signature'
import driverSteps from '@/views/Report/steps.js'
export default {
    components:{
        signature,
        TextView:Text,
        Report,
        Selector,
        ListAdd,
        Nav,
        CheckBox,
        Radio,
        Upload
    },
    props:{
        id:{
            type:String
        },
        user_token:{
            type:String
        },
        isGuide:{
            type:Boolean
        }
    },
    data(){
        return{
            isHint:false,//提示内容
            showDownload:false,
            codeUrl:"",
            dialogTableVisible:false,
            size:"50%",
            drawer:false,
            loading:true,
            dialog:false,
            tid:'',
            url:'',
            task:{},
            data:[],
            token:'',
            form:{
                email:''
            },
            spendTime:'',
            record:[],
            cid:'',//客户id
            client_num:'',//客户编号
            downForm:{
                type:"pdf",//下载类型,pdf,word
                compressed:false,//是否压缩,compressed,normal
                contents:false,//下载目录
            },
            refresh_:false,//是否再次刷新
        }
    },
    mounted(){
        //获取报告,若不是为组件
        if(!this.tid||!this.token){
                this.tid = this.$route.params.tid
                this.token = this.$route.query.token
                this.data = ListJson
                this.url = this.API.api+"?token="+this.token
                localStorage.setItem('TargetUrl',this.url)
                this.isHint = this.$route.query.isHint
                this.getReport()
        }
    },
    watch:{
        id:{
            immediate: true,
            handler(val){
                //console.log('getId',val)
                this.tid = val 
                this.data = ListJson
                this.loading = true
                setTimeout(() => {
                    this.url = this.API.api+"?token="+this.token
                    localStorage.setItem('TargetUrl',this.url)
                    this.getReport()
                }, 500);
            },
            deep:true
        },user_token:{
            immediate: true,
            handler(val){
                console.log('getToken',val)
                this.token = val
                
            },
            deep:true
        },
        isGuide:{
            immediate:true,
            handler(val){
                this.isHint = val||false
            }
        }
    },
    created(){
        if(document.body.clientWidth<768){
            this.size="100%"
        }
        //
        console.log(wx.miniProgram)
    },
    methods:{
        guaid(){
            //引导页
            let hasGuide = window.localStorage.getItem("hasGuide_show")
            //console.log(hasGuide,this.isHint)
            if(this.isHint&&!hasGuide){
                this.$Driver.defineSteps(driverSteps)
                this.$Driver.start()
                window.localStorage.setItem("hasGuide_show",'true')
            }
            
        },
        onReady(){
            console.log('---reday')
        },
        getCode(){
            let params = {
                method:'getBindUrl',
            }
             this.loading = true
             let num = this.client_num,tid=this.task.tid,cid=this.cid
             let url = this.API.codeUrl+'?method=getBindUrl&num='+num+'&tid='+tid+'&cid='+cid+'&'
             this.$ajax.get(url,params).then(res=>{
                console.log(res)
                this.loading = false
                if(res.data){
                    this.codeUrl = this.API.code+res.data
                    this.dialogTableVisible = true
                }
             }).catch(err=>{
                 console.log(err)
                 this.loading=false
                 this.$message({
                     message: err.msg?err.msg:err,
                     type: 'warning'
                 });
             })
        },
        history(){
            this.drawer = true
        },
        async email(){
            this.loading = true
            this.$refs['ruleForm'].validate((valid) => {
                if(valid){
                       this.donwloadThisFile((res)=>{
                           if(res){
                               let file = res,
                               params = {
                                    method:"sendEmailReport",
                                    client:this.task.client_name,
                                    date:this.task.start_time,
                                    email:this.form.email,
                                    tid:this.tid,
                                    hasToken:true,
                                    file,
                                    loading:true
                                }
                                console.log(params)
                                this.loading = true
                                this.$ajax.post(this.url,params).then(res=>{
                                    //console.log(res)
                                    this.loading = false
                                    this.form.email = ''
                                    this.dialog = false
                                    this.$message({
                                        message: res.msg||'发送成功',
                                        type: 'success'
                                    });
                                }).catch(err=>{
                                    console.log(err)
                                    this.loading=false
                                    this.dialog = false
                                    this.$message({
                                        message: err.msg?err.msg:err,
                                        type: 'warning'
                                    });
                                })
                           }
                       }) 
                }
            })
        },
        async downloadPdf(){
            return new Promise((resolve,reject)=>{
                //如果下载pdf版本
            let params = {
                    method:"downloadReport",
                    tid:this.tid,
                    firmid:this.task.firmid,
                    loading:true,
                    token:this.token,
                    hasToken:true,
                }
                let url = this.API.api+'report/?method=ID&tid='+this.tid+'&firmid='+this.task.firmid
                +'&token='+this.token
                if(this.downForm.contents){
                    url+="&contents=1"
                }

                if(this.downForm.compressed){
                    params.zip=true
                    url+="&zip=true"
                }
                console.log(params)
                this.loading = true
                this.$ajax.get(url,params).then(res=>{
                    this.loading = false
                    console.log(res)
                    if(res.data){
                        let url = this.API.apiUrl+res.data 
                        resolve({url})
                    }else{
                        reject(null)
                    }
                 }).catch(err=>{
                     console.log(err)
                     reject(err)
                    this.loading=false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
            })
        },
        //下载文件
        async donwloadThisFile(callback){
            let data={}
            if(this.downForm.type=="word"){
                data=  await this.downloadFile()
            }else{
                data = await this.downloadPdf()
            }
            //console.log(data)
            if(data&&data.url){
                let url = data.url
                if(!callback){
                    //发送到小程序
                    wx.miniProgram.postMessage({data:{url:data.url,method:'download'}})
                    window.open(url)
                    wx.miniProgram.navigateBack({
                        delta:1
                    })
                }else{
                    return callback(url)
                }
            }else{
                this.$message({
                        message: "操作失败了！",
                        type: 'warning'
                    });
            }
            
        },
       async downloadFile(){
            let params = {
                    method:"downloadReport",
                    tid:this.tid,
                    loading:true,
                    token:this.token,
                    hasToken:true,
                }
                this.loading = true
                return new Promise((resolve,reject)=>{
                    this.$ajax.post(this.url,params).then(res=>{
                    this.loading = false
                    if(res.data){
                        let url = this.API.apiUrl+res.data
                        resolve({url})
                        //top.postMessage(url,"download")
                        // if(callback){
                        //     return callback(url)
                        // }
                        // window.open(url)
                    }
                 }).catch(err=>{
                     reject(err)
                    this.loading=false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
                })
        },
        download(){
            this.showDownload=true;
            // this.$confirm('确认下载报告？')
            // .then(()=> {
            //     //done();
            //     this.downloadFile()
            // })
            // .catch(() => {});
        },
        share(){
            this.$confirm('确认分享报告？')
            .then(()=> {
                //done();
                this.getCode()
            })
            .catch(() => {});
        },
        //获取报告
        getReport(){
            //this.url = this.API.api+"?token="+this.token
            let params = {
                method:"getWorkorderReport",
                tid:this.tid,
                hasToken:true
            }
            this.$ajax.post(this.url,params).then(res=>{
                //TODO
                if(!res){
                    return
                }
                this.task = res.data.task[0]
                this.loading = false
                if(!res.data.data[0]){
                    return
                }
                let data = res.data.data[0].content
                //console.log('record',JSON.parse(res.data.data[0].log))
                this.record = JSON.parse(res.data.data[0].log)
                this.spendTime = res.data.data[0].spend
                if(data){
                    this.data = JSON.parse(data)
                }
                this.form.email = res.data.data[0].email||''
                this.cid = res.data.data[0].cid||''
                this.client_num = res.data.data[0].client_num||''
                document.title = this.task.client_name||'报告详情'
                this.guaid()
            }).catch(err=>{
                //Err
                this.loading=false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
@media screen and (max-width: 800px) {
    .page{
      padding:0;
    }
    .el-message-box{
        width:auto;
        min-width:300px ;
    }
    .el-card__body{
        padding:5px;
    }
    .el-dialog{
        min-width: 300px;
        margin:0 auto;
    }
}
.view_box{
    margin: 0 auto;
    max-width: 680px;
}
.page-box{
    margin-bottom: 15px;
}
.imgCode{
    width:100%;
    text-align: center;
}
.span-title{
    padding:10px 15px;
    line-height: 30px;
}
</style>