/**
 * 引导元素信息配置
 * 上一步下一步按钮文字都可以配置
 */
 export default [
    {
      element: '#download',  //设置需要引导的元素id
      popover: {
        title: '下载',
        description: '你可以点击下载报告',
        position: 'top',
        className: 'first-step-popover-class', //自定义样式
        nextBtnText: '(1/3)已知晓，下一步' //这里也可以自定义下一步按钮上的文字哦
      }
    },
    {
      element: '#share',
      popover: {
        title: '分享',
        description: '也点击分享报告',
        position: 'top',
        className: 'spareemailstyle',
        doneBtnText: '(2/3)下一步'
      }
    },{
      element: '#email',
      popover: {
        title: '邮件',
        description: '也可以邮件发送报告',
        position: 'top',
        className: 'spareemailstyle',
        doneBtnText: '(3/3)我知道了'
      }
    }
  ];
  
  