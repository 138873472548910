<template>
    <div class="t-signature">
        <div class="staff-sign" v-if="item.data.staff_sign">
        <van-cell title="服务人员签名" />
        <el-image
            style="width: 260px; height: 100px"
            :src="item.data.staff_sign"
            fit="contain"></el-image>
        </div>
        <div class="staff-sign" v-if="item.data.client_sign">
            <van-cell title="客户签名" />
            <el-image
            style="width: 260px; height: 100px"
            :src="item.data.client_sign"
            fit="contain"></el-image>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Icon,Cell } from 'vant';
Vue.use(Cell);
Vue.use(Icon);
export default {
    name:"Radio",
    props:{
        item:{
            type:Object
        }
    },
    data(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>
.t-signature{
    display: flex;
    flex-wrap: wrap;
}
</style>