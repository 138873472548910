<template>
    <div class="selector-box">
        <van-cell :title="item.title" :value="item.data||'无内容'" border/>
    </div>
</template>
<script>
import Vue from 'vue';
import { Icon, Grid, GridItem,Field,Uploader,Cell } from 'vant';

Vue.use(Field);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Uploader);
Vue.use(Cell);
Vue.use(Icon);
export default {
    name:"Selector",
    props:{
        item:{}
    },
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>