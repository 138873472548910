<template>
    <div class="usage-box">
        <span v-for="(item,index) in list" :key="index">
            {{item.name+' #'+item.num+": "+item.t_usage+item.unit }} ;
        </span>
    </div>
</template>
<script>
export default {
    props:{
        tid:{
            type:String
        }
    },
    data(){
        return{
            url:"",
            list:[]
        }
    },
    mounted(){
        this.url = localStorage.getItem('TargetUrl')
        this.getUsage()
    },
    methods:{
        getUsage(){
            let params = {
                    method:"getUsage",
                    tid:this.tid,
                    loading:true,
                    hasToken:true
                }
                //console.log(params)
                this.$ajax.post(this.url,params).then(res=>{
                    //console.log(res)
                    if(res.data){
                        console.log(res.data)
                        this.list=res.data
                    }
                 }).catch(err=>{
                    console.log(err)
                    this.loading=false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
        }
    }
}
</script>
<style lang="less" scoped>
.usage-box{
    padding:10px 15px;
    color: #999;
}
</style>