<template>
    <div class="img-box">
        <el-image
        v-for="(item,index) in fileList" :key="index"
        style="width: 100px; height: 100px"
        :src="item.url"
        :preview-src-list="srcList"
        >
            <div slot="error" class="image-slot">
                <el-button plain size="mini" type="text" @click="errClick(index,item.url)">重新加载</el-button>
            </div>
        </el-image>
    </div>
</template>
<script>
export default {
    props:{
        fileList:{
            type:Array,
        }
    },
    data(){
        return{
            srcList:[]
        }
    },
    watch:{
        fileList:{
            handler(val,old){
                if(val!=old){
                    let list = []
                    val.forEach(item=>{
                        list.push(item.url)
                    })
                    this.srcList = list
                }
            },
            deep:true,
            immediate:true
        }
    },
    methods:{
        errClick(index,url){
            console.log(index,url)
            let str = url.split("/")
            //console.log(str[str.length-1],this.API.apiUrl+'upload/'+str[str.length-1])
            this.fileList[index].url = this.API.apiUrl+'upload/'+str[str.length-1]
            let list = []
            this.fileList.forEach(item=>{
                list.push(item.url)
            })
            this.srcList = list
        }
    }
}
</script>
<style lang="less">
.img-box{
    display: flex;
    flex-wrap: wrap;
    .el-image{
        margin:5px 15px;
    }
    .image-slot{
        width:100%;
        height: 100%;
        background: #f8f8f8;
        vertical-align: middle;
        line-height: 100px;
        text-align: center;
    }
}
</style>