<template>
    <div class="list-box">
        <div class="list-box-item"  v-for="(item,index) in item.setting.list" :key="index">
        <el-card class="box-card">
              <van-cell :title="item.title" icon="location" title-style="font-size:20px">
                <div slot="icon">
                    <i class="el-icon-caret-right" style="font-size:20px"/>
                </div>
            </van-cell>
            <div v-if="item.type&&item.type=='device'">
                <Device :tid="taskid"/>
            </div>
            <div v-else-if="item.type&&item.type=='usage'">
                <UsageBox :tid="taskid" />
            </div>
            <div class="m-item" v-for="(item1,index1) in item.pages" :key="index1">
                <Selector v-if="item1.menu=='selector'" :item="item1"/>
                <Upload :item="item1" v-else-if="item1.menu=='upload'&&item1.data" />
                <ListAdd :item="item1" :title="item.title" v-else-if="item1.menu=='ListAdd'" />
                <TextView :item="item1" v-else-if="item1.menu=='text'&&item1.data" />
                <CheckBox :item="item1" v-else-if="item1.menu=='checkBox'&&item1.data" />
                <Radio :item="item1" v-else-if="item1.menu=='radio'&&item1.data" />
                <Radio :item="item1" v-else-if="item1.menu=='input'&&item1.data" />
            </div>
        </el-card>
        </div>
    </div>
</template>
<script>
import Device from '@/components/Report/Device.vue'
import Selector from '@/components/Report/Selector'
import Upload from '@/components/Report/Upload'
import ListAdd from '@/components/Report/ListAdd.vue'
import TextView from '@/components/Report/Text'
import CheckBox from '@/components/Report/CheckBox'
import Radio from '@/components/Report/Radio'
import UsageBox from '@/components/Report/Usage.vue'
export default {
    name:"Nav",
    components:{
        Device,
        Selector,
        Upload,
        ListAdd,
        TextView,
        CheckBox,
        Radio,
        UsageBox
    },
    props:{
        tid:{
            type:[String,Number]
        },
        item:{
            type:Object
        }
    },
    watch:{
        tid:{
            handler(val){
                this.taskid = val
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        return{
            taskid:''
        }
    },
    methods:{

    }
}
</script>
<style lang="less">
.list-box{
    background: #F8F8F8;
    border:1px #f0eeee dashed;
}
</style>