<template>
    <div class="box-text">
        <van-cell center :title="item.title" :label="item.data" v-if="item.data"/>
    </div>
</template>
<script>
import Vue from 'vue';
import { Icon,Cell } from 'vant';
Vue.use(Cell);
Vue.use(Icon);
export default {
    name:"TextView",
    props:{
        item:{
            type:Object
        }
    },
    data(){
        return{

        }
    }
}
</script>
<style lang="less">

</style>