<template>
    <div>
        <van-cell v-if="title" :title="item.title"/>
        <div class="box">
        <ImageGroup :fileList="item.data" />
        </div>
    </div>
    
</template>
<script>
import ImageGroup from '@/components/Report/ImageGroup'
import Vue from 'vue';
import { Icon, Uploader,Cell } from 'vant';
Vue.use(Uploader);
Vue.use(Cell);
Vue.use(Icon);
export default {
    name:"Upload",
    components:{
        ImageGroup
    },
    props:{
        title:{
            type:Boolean
        },
        item:{
            type:Object
        }
    },
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>
<style lang="less">
.slot{
    background: #fff;
}
</style>