<template>
    <div class="device-box">
        <el-table
        v-for="item in dataList"
        :key="item.title"
        border
        :data="item.res"
        style="width: 100%">
        <el-table-column type="expand">
        <template slot-scope="props">
            <div class="item-c">
                <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="设备编号">
                    <span>{{props.row.num}}</span>
                </el-form-item>
                <el-form-item label="检查时间">
                    <span>{{props.row.time}}</span>
                </el-form-item>
                <el-form-item label="操作">
                    <span>{{props.row.data_check|getDataCheck}}</span>
                </el-form-item>
                </el-form>
                 <!--  -->
                <el-form label-position="left" class="t-remark"  v-if="props.row.remark">
                <el-form-item label="备注">
                    <span>{{props.row.remark}}</span>
                </el-form-item>
                </el-form>
                <div class="imgs-box">
                    <div class="img-d"
                        v-for="item in props.row.imgs"
                        :key="item.url">
                        <el-image
                        style="width: 100px; height: 100px"
                        :preview-src-list="props.row.imgs|getImgsViews"
                        :src="item.url"></el-image>
                    </div>
                </div>
            </div>
        </template>
        </el-table-column>
        <el-table-column
        :label="item.name"
        prop="name">
         <template  slot-scope="props">
             <span>{{props.row.name}}_{{props.row.num}}</span>
         </template>
        </el-table-column>
        <el-table-column
        label="设备数据"
        prop="id">
        <template slot-scope="props">
            {{props.row.data_num|getNumbs}}
        </template>
        </el-table-column>
    </el-table>
    </div>
</template>
<script>
export default {
    props:{
        tid:{
            type:[String,Number]
        }
    },
    data(){
        return{
            dataList:[],
            url:''
        }
    },
    watch:{
        tid:{
            handler(){
                this.url = localStorage.getItem('TargetUrl')
                this.getDeviceCheckedList()
            },
            immediate:true,
            deep:true
        }
    },
    filters:{
        getImgsViews(arr){
            let img = []
            arr.forEach(item=>{
                img.push(item.url)
            })
            return img
        },
        getNumbs(data){
            let str = '',json
            try {
                json = JSON.parse(data)
                json.forEach(item=>{
                    str += item.name+':'+item.num+item.unit+';'
                })
            } catch (error) {
                console.log(error)
            }
            return str
        },
        getDataCheck(data){
            let str='',json
            try {
                json = JSON.parse(data)
                json.forEach(item=>{
                    if(item.checked){
                        str += item.name+';'
                    }
                })
            } catch (error) {
                console.log(error)
            }
            return str
        }
    },
    methods:{
        regroupByItem(arr){
            var newArr = [];
                arr.forEach(function (oldData) {
                var index = -1;
                var alreadyExists = newArr.some(function (newData, j) {
                    if (oldData.name=== newData.name) {
                        index = j;
                        return true;
                    }
                });
                try {
                    oldData.imgs = JSON.parse(oldData.camera)
                } catch (error) {
                    console.log(error)
                }
                if (!alreadyExists) {
                    var res=[];
                    res.push(oldData);
                    newArr.push({
                        name: oldData.name,
                        res: res
                    });
                } else {
                    newArr[index].res.push(oldData);
                }
                });
                    return newArr;
        },
        //获取设备检查
        getDeviceCheckedList(){
            let params = {
                    method:"getDeviceHistory",
                    tid:this.tid,
                    loading:true,
                    hasToken:true
                }
                this.$ajax.post(this.url,params).then(res=>{
                    //console.log(res)
                    if(res.data){
                        this.dataList= this.regroupByItem(res.data)
                        console.log(this.dataList)
                        this.$forceUpdate()
                    }
                 }).catch(err=>{
                    console.log(err)
                    this.loading=false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
        }
    }
}
</script>
<style lang="less">
 .demo-table-expand {
    font-size: 0;
  }
  .t-remark{
      font-size: 0;
  }
  .demo-table-expand label ,.t-remark label{
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .item-c{
      padding:10px;
  }
  .imgs-box{
      display: flex;
      flex-wrap: wrap;
      .img-d{
          margin:10px;
      }
  }
</style>