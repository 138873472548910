<template>
    <div class="list-box">
        <el-card class="box-card">
        <van-cell :title="item.title||title" icon="location" title-style="font-size:20px">
            <div class="left-icon" slot="icon">
            </div>
        </van-cell>
        <div class="list_item" v-for="(item1,index1) in item.data" :key="index1">
                <!-- <div v-for="(item2,index2) in item1" :key="index2"> -->
                     <el-badge :value="index1+1" class="item">
                     </el-badge>
                    <div v-for="(item3,index3) in item1" :key="index3">
                        <!-- {{item3|isJSON}} -->
                        <div :class="item1.length>1&&index3==item1.length-1?'l-i':''"
                        v-if="item3.menu&&item3.data">
                        <Selector v-if="item3.menu=='selector'&&item3.data" :item="item3"/>
                        <Upload :item="item3" v-else-if="item3.menu=='upload'&&item3.data" />
                        <TextView :item="item3" v-else-if="item3.menu=='text'&&item3.data" />
                        <CheckBox :item="item3" v-else-if="item3.menu=='checkBox'&&item3.data.length>0" />
                        <Radio :item="item3" v-else-if="item3.menu=='radio'&&item3.data" />
                        <Radio :item="item3" v-else-if="item3.menu=='input'&&item3.data" />
                        </div>
                        
                    <!-- </div> -->
                </div>
        </div>
        </el-card>
    </div>
</template>
<script>
import Selector from '@/components/Report/Selector'
import Upload from '@/components/Report/Upload'
import TextView from '@/components/Report/Text'
import CheckBox from '@/components/Report/CheckBox'
import Radio from '@/components/Report/Radio'
export default {
    name:"ListAdd",
    components:{
        Selector,
        Upload,
        TextView,
        CheckBox,
        Radio
    },
    props:{
        item:{
            type:[Object,Array]
        },
        title:{
            type:String
        }
    },
    data(){
        return{
            data:[]
        }
    },
    methods:{

    }
}
</script>
<style lang="less">
.list-box{
    border:1px #f0eeee dashed;
    display: flex;
    flex-direction: column;
    .list-item{
        border-bottom: 1px #DBDBDB solid;
    }
    .left-icon{
        width:5px;
        height: 20px;
        background: #39F;
        margin:0 5px;
        vertical-align: middle;
    }
    .l-i{
        border-bottom: 1px #DBDBDB solid;
    }
    .item{
        margin-left:10px;
        margin-top:10px;
    }
}
</style>